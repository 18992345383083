var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-btn',{staticClass:"mb-2",attrs:{"text":"","color":"accent"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left-thick")]),_vm._v(" Back ")],1),_c('h1',[_vm._v("Withdraw")]),(_vm.userCountrySetting.withdrawalFeatureAvailable)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-form',{ref:"transferForm",on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('v-card',{attrs:{"loading":_vm.loadingTransfer,"flat":""}},[_c('v-container',[_c('v-card-title',[_vm._v("Withdraw Fund")]),_c('v-card-text',[_c('p',[_vm._v(" Your currently have "),_c('b',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.userCurrency)+" "+_vm._s(_vm.wallet.balance || 0)+" ")]),_vm._v(" in your wallet. ")]),_c('p',{staticClass:"text-h6"},[_vm._v("Pick a withdraw Method")]),_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'amber' : '',"height":"125"},on:{"click":toggle}},[_c('v-scroll-y-transition',[_c('div',{staticClass:"text-center mx-auto"},[_c('v-icon',{attrs:{"size":"35","color":"green"}},[_vm._v("mdi-bank ")]),_c('div',{class:("flex-grow-1 subtitle-2 mt-2 " + (active ? 'white--text' : 'grey--text') + " text-center"),staticStyle:{"font-size":"14px"}},[_vm._v(" Withdraw ")])],1)])],1)]}}],null,false,81177991)})],1),(_vm.showDataAndAirtime)?_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var active = ref.active;
                                var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'red' : '',"height":"125","to":"/user/products/bills/airtime/"},on:{"click":toggle}},[_c('v-scroll-y-transition',[_c('div',{staticClass:"mx-auto text-center"},[_c('v-icon',{attrs:{"size":"35","color":"indigo"}},[_vm._v("mdi-cellphone ")]),_c('div',{class:("flex-grow-1 subtitle-2 mt-2 " + (active ? 'white--text' : 'grey--text') + " text-center"),staticStyle:{"font-size":"14px"}},[_vm._v(" Buy Airtime ")])],1)])],1)]}}],null,false,2248107991)})],1):_vm._e(),(_vm.showDataAndAirtime)?_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var active = ref.active;
                                var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'indigo' : '',"height":"125","to":"/user/products/bills/internet-data/"},on:{"click":toggle}},[_c('v-scroll-y-transition',[_c('div',{staticClass:"mx-auto text-center"},[_c('v-icon',{attrs:{"size":"35","color":"red"}},[_vm._v("mdi-wifi")]),_c('div',{class:("flex-grow-1 subtitle-2 mt-2 " + (active ? 'white--text' : 'grey--text') + " text-center"),staticStyle:{"font-size":"14px"}},[_vm._v(" Buy Data ")])],1)])],1)]}}],null,false,2756644170)})],1):_vm._e()],1)],1),_c('v-select',{staticClass:"mt-5",attrs:{"filled":"","loading":_vm.withdrawalMethods.length == 0,"items":_vm.withdrawalMethods,"hint":"Select withdraw method","item-text":"name","item-value":"_id","label":"Withdrawal Method"},model:{value:(_vm.method),callback:function ($$v) {_vm.method=$$v},expression:"method"}}),(!_vm.loadingBanks && _vm.bankAccounts.length === 0)?_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" You don't have a bank account set up on your account, "),_c('router-link',{staticClass:"accent--text",attrs:{"to":"/user/settings"}},[_vm._v("click here ")]),_vm._v(" to add a bank account to withdraw to ")],1)]):_c('div',[(_vm.selectedMethod === 'Bank Transfer')?_c('v-select',{attrs:{"filled":"","label":"Select Bank","items":_vm.bankAccounts,"item-value":"_id","item-text":"display","hint":"Select your preferred bank to withdraw to"},model:{value:(_vm.selectedBank),callback:function ($$v) {_vm.selectedBank=$$v},expression:"selectedBank"}}):_vm._e(),_c('v-text-field',{attrs:{"filled":"","disabled":_vm.loadingTransfer,"number":"","type":"number","rules":[
                      function (v) { return (v && v <= _vm.wallet.balance) ||
                        'You don\'t have that much in your wallet'; },
                      function (v) { return v > _vm.minAndMax.min ||
                        ("Minimum amount you can withdraw " + (_vm.minAndMax.min)); },
                      function (v) { return v < _vm.minAndMax.max ||
                        ("Maximum amount you can withdraw is " + (_vm.minAndMax.max)); }
                    ],"label":"Amount","hint":"Amount to Transfer to user","persistent-hint":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),(_vm.user.authenticationType === 'otp')?_c('v-text-field',{attrs:{"filled":"","label":"OTP","hint":"An OTP  will be sent to your registered email to approve this transaction","persistent-hint":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.sendingOtp,"color":"primary","dark":""},on:{"click":_vm.generateOtp}},[_vm._v(" Request OTP ")])]},proxy:true}],null,false,696600444),model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}):_vm._e(),(_vm.user.authenticationType === 'pin')?_c('v-text-field',{attrs:{"filled":"","label":"PIN","hint":"Enter your PIN","persistent-hint":""},model:{value:(_vm.transactionPin),callback:function ($$v) {_vm.transactionPin=$$v},expression:"transactionPin"}}):_vm._e(),_c('div',{},[_c('p',{staticClass:"mb-2 text-sm font-semibold"},[_vm._v(" Withdrawal fee applies "),_c('v-tooltip',{attrs:{"max-width":"250","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"border p-1 text-gray-400 text-xs rounded-full border-gray-400"},'span',attrs,false),on),[_vm._v(" ? ")])]}}],null,false,2265710555)},[_c('small',{staticStyle:{"font-size":"10px","line-height":"2px"}},[_vm._v(" Withdrawal fee: To ensure that the usual hassle/delays that afters day to day online transfers do not delay you, we engaged the services of some Banking Partners to speed up Withdrawals, this fee servers as a premium for this service ")])])],1),_c('p',{staticClass:"mb-0"},[_vm._v(" You will be charged "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.userCurrency)+" "+_vm._s(_vm.withdrawalCharge))])]),_c('p',{staticClass:"mb-3"},[_vm._v(" You will receive "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.userCurrency)+" "+_vm._s(_vm.amount === '' ? 0 : Number(_vm.amount) - _vm.withdrawalCharge))])])]),_c('p',[_vm._v(" After this transfer is approved "),_c('b',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.userCurrency)+" "+_vm._s(_vm.amount)+" ")]),_vm._v(" will be removed from your wallet and you will have "),_c('b',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.userCurrency)+" "+_vm._s(Number(_vm.wallet.balance) - Number(_vm.amount)))]),_vm._v(" left in your wallet ")]),_c('v-btn',{attrs:{"block":"","type":"submit","loading":_vm.loadingTransfer,"large":"","color":"primary","disabled":_vm.amountToRemove <= 0 ||
                      _vm.amount < _vm.minAndMax.min ||
                      _vm.amount > _vm.minAndMax.max}},[_vm._v(" Withdraw ")])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{model:{value:(_vm.logsTab),callback:function ($$v) {_vm.logsTab=$$v},expression:"logsTab"}},[_c('v-tab',[_vm._v(" Approved")]),_c('v-tab',[(_vm.pendingTransfers.length === 0)?_c('span',[_vm._v(" Pending ")]):_c('v-badge',{attrs:{"color":"pink","content":_vm.pendingTransfers.length}},[_vm._v(" Pending ")])],1),_c('v-tab',[_vm._v(" Declined")])],1)],1),_c('v-sheet',[_c('v-tabs-items',{model:{value:(_vm.logsTab),callback:function ($$v) {_vm.logsTab=$$v},expression:"logsTab"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"loading":_vm.loading,"footer-props":{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  },"item-key":"_id","items":_vm.approvedTransfers,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount)+" "),_c('small',[_vm._v(_vm._s(_vm.userCurrency))])])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No approved Transfer")])],1)]},proxy:true}],null,true)})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"loading":_vm.loading,"footer-props":{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  },"item-key":"_id","items":_vm.pendingTransfers,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount)+" "),_c('small',[_vm._v(_vm._s(_vm.userCurrency))])])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No pending Transfer")])],1)]},proxy:true}],null,true)})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"loading":_vm.loading,"footer-props":{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100, -1]
                  },"item-key":"_id","items":_vm.declinedTransfers,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount)+" "),_c('small',[_vm._v(_vm._s(_vm.userCurrency))])])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No declined Transfer")])],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1)],1)],1):_c('div',[_c('v-card',{staticClass:"pa-5",attrs:{"flat":"","rounded":"lg"}},[_vm._v(" "+_vm._s(_vm.userCountrySetting.withdrawalFeatureMessage || 'Withdrawal feature is currently in maintenance, please check back later')+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }